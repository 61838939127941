import { useConfig } from '@Src/providers/ConfigProvider';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';
import { useEffect, useState } from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import MenuSlider from './Components/MenuSlider';
import '@Components/ui/ScrollingMenuHeader/hideScrollBar.css';
import { formatMenuHighlights } from './formatMenuHighlights';
import { type Menu, type MenuItemHighlight } from './types';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { getStoreOrderUrl } from '@Src/utility/orderUtils';
import { setOrderUrl } from '@Src/slices/orderUrlSlice';
import { EditOrderSettingsDialog } from '@Components/Widgets/OrderSettings/EditOrderSettingsDialog';
import {
  selectIsNoStoreSelected,
  selectShouldForceCollectionMenuUrl,
} from '@Src/selectors/stores.selectors';
import { useGetMenuDataFromStoreId } from '@Src/hooks/useGetMenuDataFromStoreId';

type MenuHighlightsProps = {
  borderRadius?: string;
  buttonBorderRadius?: string;
  buttonTextColor?: string;
  menuCardBorderRadius?: string;
  menuCardBorderWidth?: string;
  menuCardImageBorderRadius?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  dataFromStoreId?: boolean;
};
const MenuHighlights = ({
  borderRadius,
  buttonBorderRadius,
  buttonTextColor,
  menuCardBorderRadius,
  menuCardBorderWidth,
  menuCardImageBorderRadius,
  paddingTop,
  paddingLeft,
  paddingBottom,
  dataFromStoreId,
}: MenuHighlightsProps): JSX.Element => {
  const { appId, currencies } = useDesignSchema();
  const { language, isCustomDomain } = useConfig();
  const dispatch = useAppDispatch();

  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );
  const selectedCollectionStore = useAppSelector(
    (state) => state.collectionStores.selectedCollectionStore,
  );
  const selectedDeliveryStore = useAppSelector(
    (state) => state.deliveryStores.selectedDeliveryStore,
  );
  const deliveryStores = useAppSelector(
    (state) => state.deliveryStores.deliveryStoreList,
  );
  const orderUrl = useAppSelector((state) => state.orderUrl.path);
  const isNoStoreSelected = useAppSelector(selectIsNoStoreSelected);

  /** User has selected delivery but no stores are available and then they tap a menu item */
  const shouldForceCollectionMenuUrl = useAppSelector(
    selectShouldForceCollectionMenuUrl,
  );
  const meuData = dataFromStoreId
    ? (useGetMenuDataFromStoreId(formatMenuHighlights, {
        currency: currencies?.[0] || 'EUR',
        language,
      }) as MenuItemHighlight[])
    : [];

  const [menuItems, setMenuItems] = useState<MenuItemHighlight[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const baseUrl = isCustomDomain ? '' : `/${appId}`;
  const store = isCollection
    ? selectedCollectionStore
    : selectedDeliveryStore || deliveryStores[0];

  useEffect(() => {
    if (meuData.length > 0) {
      setMenuItems(meuData);
    }
  }, [meuData]);

  useEffect(() => {
    if (shouldForceCollectionMenuUrl && selectedCollectionStore) {
      const url =
        getStoreOrderUrl({
          deliveryLocationId: undefined,
          isCollection: true,
          shouldForceCollectionStoreUrl: true,
          storeSummary: selectedCollectionStore,
        }) || '/order';
      if (url) {
        void dispatch(setOrderUrl(url));
      } else {
        console.error('No menu item url');
      }
      return;
    }
    if (
      shouldForceCollectionMenuUrl &&
      !selectedCollectionStore &&
      deliveryStores?.length
    ) {
      void dispatch(setOrderUrl('/order'));
    }
  }, [shouldForceCollectionMenuUrl, selectedCollectionStore, deliveryStores]);

  const defaultMenuUrl =
    selectedCollectionStore?.MenuUrl || deliveryStores[0]?.MenuUrl;

  const { PhysicalRestaurantId, MenuUrl, MenuId, IsoCurrency } = store || {};

  const sessionStorageKey = `menuData-v2-${
    MenuId || selectedCollectionStore?.MenuId || ''
  }`;

  const getMenuAndFormat = async (
    menuSourceUrl?: string,
  ): Promise<MenuItemHighlight[] | undefined> => {
    if (!menuSourceUrl) return;
    try {
      const menuData = await fetch(menuSourceUrl);
      const menu: Menu = await menuData.json();
      return formatMenuHighlights({
        currency:
          (shouldForceCollectionMenuUrl && selectedCollectionStore
            ? selectedCollectionStore?.IsoCurrency
            : IsoCurrency) || 'EUR',
        language,
        menu,
      });
    } catch (e) {
      console.log('Error getting menu highlights', e);
    }
  };

  useEffect(() => {
    const menuFromSession = window.sessionStorage.getItem(sessionStorageKey);

    if (menuFromSession) {
      const menuData: MenuItemHighlight[] = JSON.parse(menuFromSession);
      setMenuItems(menuData);
    } else if (PhysicalRestaurantId && MenuUrl) {
      void (async () => {
        const formattedMenuHighlights = await getMenuAndFormat(
          shouldForceCollectionMenuUrl ? defaultMenuUrl : MenuUrl,
        );
        if (formattedMenuHighlights?.length) {
          window.sessionStorage.setItem(
            sessionStorageKey,
            JSON.stringify(formattedMenuHighlights),
          );
          setMenuItems(formattedMenuHighlights);
        }
      })();
    } else {
      void (async () => {
        const formattedMenuHighlights = await getMenuAndFormat(defaultMenuUrl);
        if (formattedMenuHighlights?.length) {
          window.sessionStorage.setItem(
            sessionStorageKey,
            JSON.stringify(formattedMenuHighlights),
          );
          setMenuItems(formattedMenuHighlights);
        }
      })();
    }
  }, [
    defaultMenuUrl,
    PhysicalRestaurantId,
    MenuId,
    MenuUrl,
    selectedCollectionStore,
    shouldForceCollectionMenuUrl,
  ]);

  const overrideActionClick = (): void => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <MenuSlider
        menuItems={menuItems || []}
        url={`${baseUrl}${orderUrl}`}
        overrideClickAction={
          isNoStoreSelected ? overrideActionClick : undefined
        }
        borderRadius={borderRadius}
        buttonBorderRadius={buttonBorderRadius}
        buttonTextColor={buttonTextColor}
        menuCardBorderRadius={menuCardBorderRadius}
        menuCardBorderWidth={menuCardBorderWidth}
        menuCardImageBorderRadius={menuCardImageBorderRadius}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingTop={paddingTop}
      />
      <EditOrderSettingsDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default MenuHighlights;
