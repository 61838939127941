import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { OrderTypeToggle } from './OrderTypeToggle';
import { useTranslation } from '@Src/providers/TranslationProvider';
import { useAppSelector } from '@Src/hooks/useAppSelector';
import { EditOrderSettingsDialog } from './EditOrderSettingsDialog';
import { OrderSettingDetails } from './OrderSettingDetails';
import { useOrderSettingSetup } from '@Src/hooks/useOrderSettingSetup';
import { type DeliveryFlowStepType } from './EditOrderSettingsDialog/DeliverySettings';
import Grid from '@mui/material/Grid';
import { ServiceUnavailable } from './ServiceUnavailable';
import { useAppDispatch } from '@Src/hooks/useAppDispatch';
import { setIsCollection } from '@Src/slices/orderSettingSlice';
import { LeadTime } from './LeadTime';
import {
  selectHasSingleStoreTypeOnly,
  selectHasStores,
  selectIsCollectionUnavailable,
  selectIsDeliveryUnavailable,
  selectIsServiceUnavailable,
  selectSelectedStore,
  selectShouldShowLeadTime,
} from '@Src/selectors/stores.selectors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDesignSchema } from '@Src/providers/DesignSchemaProvider';

const StyledContainer = styled('div')(() => ({
  flexDirection: 'column',
  width: '100%',
}));

const StyledToggleContainer = styled(Grid)<{
  ownerState: { isToggleHidden: boolean; isServiceUnavailable?: boolean };
}>(({ theme, ownerState }) => ({
  flexWrap: 'nowrap',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: ownerState.isToggleHidden ? theme.spacing(2) : 0,
    paddingRight: theme.spacing(1.5),
    justifyContent: 'space-between',
  },
}));

const StyledToggleContainerWrapper = styled('div')`
  min-height: 56px;
`;

const StyledServiceUnavailableWrapper = styled('div')`
  display: flex;
`;

type OrderSettingsProps = {
  borderRadius?: string;
  boxShadow?: string;
  buttonBorderRadius?: string;
  orderButtonSize?: 'lg' | 'sm';
  borderWidth?: string;
  paddingBottom?: string;
  mobileLayout?: boolean;
};

export function OrderSettings({
  borderRadius,
  borderWidth = '1px',
  boxShadow,
  buttonBorderRadius,
  orderButtonSize = 'sm',
  paddingBottom,
  mobileLayout,
}: OrderSettingsProps): JSX.Element {
  const { orderSettings } = useDesignSchema('v2');

  const [isDialogOpen, setIsDialogOpen] = useState<{
    isOpen: boolean;
    startStep?: DeliveryFlowStepType;
  }>({ isOpen: false });

  const dispatch = useAppDispatch();
  const { translate } = useTranslation();
  const theme = useTheme();
  const isSmallScreen =
    mobileLayout || useMediaQuery(theme.breakpoints.down('sm'));

  const isCollection = useAppSelector(
    (state) => state.orderSettings.isCollection,
  );

  useOrderSettingSetup();

  const selectedDeliveryAddress = useAppSelector(
    (state) => state.deliveryAddresses.selectedDeliveryAddress,
  );
  const selectedDeliveryStore = useAppSelector(
    (state) => state.deliveryStores.selectedDeliveryStore,
  );
  const isDeliveryStoresFetched = useAppSelector(
    (state) => state.deliveryStores.fetched,
  );
  const isCollectionStoresFetched = useAppSelector(
    (state) => state.deliveryStores.fetched,
  );
  const orderUrl = useAppSelector((state) => state.orderUrl.path);

  // stores list
  const deliveryStoresList = useAppSelector(
    (state) => state.deliveryStores.deliveryStoreList,
  );
  const collectionStoresList = useAppSelector(
    (state) => state.collectionStores.collectionStoreList,
  );

  const isCollectionUnavailable = useAppSelector(selectIsCollectionUnavailable);
  const isDeliveryUnavailable = useAppSelector(selectIsDeliveryUnavailable);
  const hasStores = useAppSelector(selectHasStores);
  const isToggleHidden = useAppSelector(selectHasSingleStoreTypeOnly);
  const showLeadTime = useAppSelector(selectShouldShowLeadTime);
  const isServiceUnavailable = useAppSelector(selectIsServiceUnavailable);

  // #region common store props
  const selectedStore = useAppSelector(selectSelectedStore);

  const {
    AcceptPreOrders,
    DistanceKm,
    PhysicalRestaurantAddress,
    IsOpen,
    PhysicalRestaurantName,
  } = selectedStore || {};

  const statusMessage = IsOpen ? translate('Open') : translate('Closed');
  // #endregion

  useEffect(() => {
    /**
     * Set the order type based on the following logic where initial order type is delivery (isCollection: false):
     * 1. If order type is collection or collection is unavailable, do nothing
     * 2. If default order type is collection, set the order type to collection
     * 3. If delivery is unavailable, set the order type to collection
     * 4. Change order type back to default delivery
     */
    const setOrderType = (): void => {
      if (isCollection || isCollectionUnavailable) {
        return;
      }

      const isCollectionDefault =
        orderSettings?.defaultOrderType === 'collection';
      if (isCollectionDefault) {
        dispatch(setIsCollection(true));
        return;
      }

      const isDeliveryAutoSwitchToCollection =
        !deliveryStoresList.length ||
        (deliveryStoresList.length && isDeliveryUnavailable);
      if (isDeliveryAutoSwitchToCollection) {
        dispatch(setIsCollection(true));
        return;
      }

      dispatch(setIsCollection(false));
    };

    /** Runs on page load and makes sure orderUrl is set correctly before setting order type */
    if (isDeliveryStoresFetched && isCollectionStoresFetched) {
      setOrderType();
    }
  }, [isDeliveryStoresFetched, isCollectionStoresFetched]);

  return (
    <>
      {hasStores && isDeliveryStoresFetched && (
        <StyledContainer data-fd="order-settings-container">
          <StyledToggleContainerWrapper>
            <StyledToggleContainer
              container
              ownerState={{
                isToggleHidden,
                isServiceUnavailable,
              }}
            >
              {!isToggleHidden && (
                <OrderTypeToggle
                  dataFd="order-settings"
                  padding={theme.spacing(1.5)}
                  collectionDisabled={isCollectionUnavailable}
                  collectionStoresLength={collectionStoresList?.length}
                  setIsDialogOpen={({ isOpen }) => {
                    setIsDialogOpen({ isOpen });
                  }}
                />
              )}

              {/* NGW-516 show lead time on delivery or when only one collection store while we trial a new collection flow  */}
              {((isCollection && collectionStoresList.length === 1) ||
                !isCollection) &&
                showLeadTime && <LeadTime />}

              {!isSmallScreen && (
                <StyledServiceUnavailableWrapper>
                  <ServiceUnavailable />
                </StyledServiceUnavailableWrapper>
              )}
            </StyledToggleContainer>
          </StyledToggleContainerWrapper>

          {isSmallScreen && (
            <StyledServiceUnavailableWrapper>
              <ServiceUnavailable />
            </StyledServiceUnavailableWrapper>
          )}

          <OrderSettingDetails
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            boxShadow={boxShadow}
            buttonBorderRadius={buttonBorderRadius}
            paddingBottom={paddingBottom}
            collectionStoresLength={collectionStoresList?.length}
            deliveryStoresLength={deliveryStoresList?.length}
            distanceKm={DistanceKm}
            physicalRestaurantAddress={PhysicalRestaurantAddress}
            hasSelectedDeliveryStore={!!selectedDeliveryStore}
            hasUserDeliveryAddress={!!selectedDeliveryAddress}
            locationName={
              isCollection
                ? PhysicalRestaurantName || ''
                : selectedDeliveryAddress?.TwoLinesDisplay?.[0] || ''
            }
            orderSetting={isCollection ? 'Collect' : 'Deliver'}
            orderUrl={orderUrl}
            orderButtonSize={orderButtonSize}
            setIsDialogOpen={setIsDialogOpen}
            statusMessage={statusMessage}
            storeOpen={IsOpen || false}
            storeAcceptsPreorders={AcceptPreOrders || false}
            mobileLayout={mobileLayout}
          />
        </StyledContainer>
      )}
      <EditOrderSettingsDialog
        isOpen={isDialogOpen.isOpen}
        onClose={() => setIsDialogOpen({ isOpen: false })}
        setStartStep={(deliveryFlowStep: DeliveryFlowStepType) =>
          setIsDialogOpen({ isOpen: true, startStep: deliveryFlowStep })
        }
        startStep={isDialogOpen.startStep}
      />
    </>
  );
}
